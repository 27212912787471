
const constants = {
  config: {
    language: {
      swedish: {
        name: 'sv',
        displayName: 'Svenska',
        locale: 'sv-se',
        default: true,
        enabledForFrontend: true,
        path: 'sv',
        loginProxyPath: 'services/login-proxy/'
      },
      norwegian: {
        name: 'no',
        displayName: 'Norsk',
        locale: 'nb-no',
        default: false,
        enabledForFrontend: true,
        path: 'no',
        loginProxyPath: '',
      },
      english: {
        name: 'en',
        displayName: 'English',
        locale: 'en-gb',
        default: false,
        enabledForFrontend: true,
        path: 'en',
        loginProxyPath: 'services/login-proxy/',
      },
      danish: {
        name: 'dk',
        displayName: 'Dansk',
        locale: 'da-dk',
        default: false,
        enabledForFrontend: true,
        path: 'dk',
        loginProxyPath: ''
      }
    },
  },
  build: {
    react: {
      userAgent: 'EgreementBuild'
    }
  },
  environment: {
    wwwLocal: {
      envSubdomain: 'www-local',
      proxySubdomain: 'local',
      domain: 'egreement-labs.com'
    },
    localhost: {
      envSubdomain: 'localhost',
      proxySubdomain: 'dev-egr',
      domain: 'egreement-labs.com'
    },
    dev: {
      envSubdomain: 'dev',
      proxySubdomain: 'dev-egr',
      domain: 'egreement-labs.com'
    },
    qa: {
      envSubdomain: 'test',
      proxySubdomain: 'qa-egr',
      domain: 'egreement-labs.com'
    },
    prodTest: {
      envSubdomain: 'prodtest',
      proxySubdomain: 'pro-prodtest',
      domain: 'egreement.com'
    },
    prod: {
      envSubdomain: 'www',
      proxySubdomain: 'pro',
      domain: 'egreement.com'
    }
  }
}

module.exports = Object.freeze(constants)
