import localizationUtil from "./localizationUtil";
import buildConfig from "../build.config";
import constants from '../constants'

const environmentToSubdomainMapping = [
    {
        envSubdomain: constants.environment.wwwLocal.envSubdomain,
        proxySubdomain: constants.environment.wwwLocal.proxySubdomain,
        domain: constants.environment.wwwLocal.domain
    },
    {
        envSubdomain: constants.environment.localhost.envSubdomain,
        proxySubdomain: constants.environment.localhost.proxySubdomain,
        domain: constants.environment.localhost.domain
    },
    {
        envSubdomain: constants.environment.dev.envSubdomain,
        proxySubdomain: constants.environment.dev.proxySubdomain,
        domain: constants.environment.dev.domain
    },
    {
        envSubdomain: constants.environment.qa.envSubdomain,
        proxySubdomain: constants.environment.qa.proxySubdomain,
        domain: constants.environment.qa.domain
    },
    {
        envSubdomain: constants.environment.prodTest.envSubdomain,
        proxySubdomain: constants.environment.prodTest.proxySubdomain,
        domain: constants.environment.prodTest.domain
    },
    {
        envSubdomain: constants.environment.prod.envSubdomain,
        proxySubdomain: constants.environment.prod.proxySubdomain,
        domain: constants.environment.prod.domain
    },
];

const getDefaultEnvironmentConfig = function () {
    return environmentToSubdomainMapping.filter(it => it.proxySubdomain === 'pro');
}

const getSubdomain = function (url) {
    return url.split('.')[0];
}

const getFormattedProxyUrl = function (environmentConfig, languageConfig) {
    return 'https://' + environmentConfig.proxySubdomain + "." + environmentConfig.domain + '/' + languageConfig.loginProxyPath;
}

const loginProxyHelper = {
    resolve: function (hostname, locale) {
        /**
         * When react-snap builds the pre-rendered structure of the website, the hostname's value is `localhost`. 
         * This condition checks if the function is executing from a react build, if so use the production proxy url
         */
        if (window.navigator.userAgent === buildConfig.react.userAgent) {
            return getFormattedProxyUrl(getDefaultEnvironmentConfig()[0], localizationUtil.getLanguageFromLocale(locale));
        }

        const subdomain = getSubdomain(hostname);
        const languageConfig = localizationUtil.getLanguageFromLocale(locale);
        let config = environmentToSubdomainMapping.filter(it => it.envSubdomain === subdomain);
        if (config === null || config === undefined || config.length === 0) {
            config = getDefaultEnvironmentConfig();
        }

        return getFormattedProxyUrl(config[0], languageConfig);
    }
};

export default loginProxyHelper;
