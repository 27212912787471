import React from "react";
import loginProxyByHostName from "../../util/loginProxyByHostName";
import util from "../../util/util";
import { NavLink, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../../logo.svg";
import ServiceDropdown from "./ServiceDropdown";
import Arrow from "../Arrows";
import PricesDropdown from "./PricesDropdown";
import localizationUtil from "../../util/localizationUtil";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navMainExpanded: false,
      toggleNavUser: props.navUserToggle,
      loginProxyUrl: "",
      serviceArrowDirection: "right",
      pricesArrowDirection: "right",
    };
  }

  dropdownListElements = "";

  componentDidMount() {
    this.listOfDropdownListLinks = document.querySelectorAll(".dropdown-li");
    this.serviceLink = document.querySelector("#service-link-li");

    const loginProxyUrl = loginProxyByHostName.resolve(
      window.location.hostname,
      localizationUtil.getUserLanguagePreference(window, localStorage).locale);
    this.setState({ loginProxyUrl });
    this.dropdownListElements = document.querySelectorAll(".dropdown-li");
    document.querySelector("#main-logo-link").classList.remove("active");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.navMainExpanded !== this.state.navMainExpanded) {
      this.props.onNavigationLayoutChanged(this.state.navMainExpanded);
    }
  }

  navigationClickHandler = () => {
    this.setState({
      navMainExpanded: false
    })
  }

  toggleHamburgerMenu = () => {
    this.setState((state) => {
      return { navMainExpanded: !state.navMainExpanded }
    });
  };

  getNavLinkClass = (path) => {
    return this.props.location.pathname.includes(path) ? "active" : "";
  };

  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
    const routerLanguage = localizationUtil.getLanguageWithoutCountry(window, localStorage);
    const fields = util.getDocument("component_" + language, "navbar");
    const getRouteByID = util.getRouteByID;
    const getRouteTextByID = util.getRouteTextByID;

    const smallHomeLink = document.querySelector(
      "#home-link-small-screen-aTag"
    );
    if (smallHomeLink != null) {
      if (
        window.location.origin + "/" === window.location.href ||
        window.location.origin === window.location.href
      ) {
        smallHomeLink.classList.add("active");
      } else {
        smallHomeLink.classList.remove("active");
      }
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("scroll", () => {
      //this function deals with adding shadow and color to the header on scroll.
      const ulWithShadow = document.querySelector(".header-ul");
      const visualBackgroundForSmallScreen = document.querySelector(
        ".visual-background-for-small-screen"
      );

      //first block is dealing with larger screens

      if (
        "ontouchstart" in document.documentElement !== true &&
        window.innerWidth >= 1024
      ) {
        if (window.scrollY < 70) {
          ulWithShadow.style.boxShadow = "none";
        } else {
          ulWithShadow.style.boxShadow = "0px -10px 50px #acacac";
        }

        //second is dealing with smaller screens(hamburger menu)
      } else {
        ulWithShadow.style.boxShadow = "none";
        if (window.scrollY < 70) {
          visualBackgroundForSmallScreen.style.boxShadow = "none";
        } else {
          visualBackgroundForSmallScreen.style.boxShadow =
            "0px 0px 50px #acacac";
        }
      }

      if (
        this.state.navMainExpanded === true &&
        visualBackgroundForSmallScreen.style.boxShadow !== "none"
      ) {
        visualBackgroundForSmallScreen.style.boxShadow = "none";
        ulWithShadow.style.boxShadow = "none";
      }
    });

    const isTouchScreen = () => {
      if ("ontouchstart" in document.documentElement) {
        return true;
      }
    };

    const hoverOverServiceDropdown = () => {
      if (!isTouchScreen() && window.innerWidth >= 1024) {
        const serviceUl = document.querySelectorAll(".service-dropdown-ul")[0];
        serviceUl.style.display = "flex";
        this.setState({
          serviceArrowDirection: "down",
        });
      }
    };

    const mouseOutServiceDropDown = () => {
      if (!isTouchScreen() && window.innerWidth >= 1024) {
        const serviceUl = document.querySelectorAll(".service-dropdown-ul")[0];
        serviceUl.style.display = "none";
        this.setState({
          serviceArrowDirection: "right",
        });
      }
    };

    const hoverOverPricesDropdown = () => {
      if (!isTouchScreen() && window.innerWidth >= 1024) {
        const pricesUl = document.querySelectorAll(".prices-dropdown-ul")[0];
        pricesUl.style.display = "flex";
        this.setState({
          pricesArrowDirection: "down",
        });
      }
    };

    const mouseOutPricesDropDown = () => {
      if (!isTouchScreen() && window.innerWidth >= 1024) {
        const pricesUl = document.querySelectorAll(".prices-dropdown-ul")[0];
        pricesUl.style.display = "none";
        this.setState({
          pricesArrowDirection: "right",
        });
      }
    };

    return (
      <header>
        <label
          htmlFor="navMainExpanded"
          className="toggle-nav toggle-nav-main"
        ></label>
        <input
          type="checkbox"
          id="navMainExpanded"
          name="navMainExpanded"
          defaultChecked={this.state.navMainExpanded}
          onChange={this.toggleHamburgerMenu}
        />
        <label
          htmlFor="toggleNavUser"
          className="toggle-nav toggle-nav-user"
        ></label>
        <input
          type="checkbox"
          id="toggleNavUser"
          name="toggleNavUser"
          defaultChecked={this.props.navUserToggle}
          onChange={this.toggleHamburgerMenu}
        />

        <div className={this.state.navMainExpanded ? "hidden visual-background-for-small-screen" : "visual-background-for-small-screen"}> </div>
        {/* small screen */}
        <NavLink
          id="main-logo-outside-nav-link"
          to="/"
          onClick={this.navigationClickHandler}
        >
          <Logo />
        </NavLink>

        <nav className={this.state.navMainExpanded ? "nav-main expanded" : "nav-main"}>
          <ul className="header-ul">
            <li
              className={`nav-main-item_home-link`}
              id="home-link-small-screen-li"
            >
              <NavLink
                id="home-link-small-screen-aTag"
                to="/"
                onClick={this.navigationClickHandler}
              >
                {fields.homeLinkText}
              </NavLink>
            </li>
            <li className="text-li border-hover-effect">
              <a className="" href={this.state.loginProxyUrl}>
                {fields.login}
              </a>
            </li>
            <li className={`border-hover-effect text-li`}>
              <NavLink
                className={`${this.getNavLinkClass(
                  getRouteByID("supportPage")
                )}`}
                to={getRouteByID("faq")}
                onClick={this.navigationClickHandler}
              >
                {getRouteTextByID("faq")}
              </NavLink>
            </li>
            <li className={`border-hover-effect text-li`}>
              <NavLink
                className={`${this.getNavLinkClass(
                  getRouteByID('newsList')
                )}`}
                to={getRouteByID('newsList')}
                onClick={this.navigationClickHandler}
              >
                {getRouteTextByID('newsList')}
              </NavLink>
            </li>
            <li className={`border-hover-effect text-li`}>
              <NavLink
                className={`${this.getNavLinkClass(getRouteByID("customerCases"))}`}
                to={getRouteByID("customerCases")}
                onClick={this.navigationClickHandler}
              >
                {getRouteTextByID("customerCases")}
              </NavLink>
            </li>
            <li className={`nav-main-item_home-link`} id="main-logo-li">
              <a id="main-logo-link" href={`/${routerLanguage}`} onClick={this.navigationClickHandler}>
                <Logo />
              </a>
            </li>
            <li
              onMouseOver={hoverOverServiceDropdown}
              onMouseOut={mouseOutServiceDropDown}
              id="service-link-li"
              className="text-li"
            >
              <NavLink
                id="service-link"
                className="text-li"
                to={getRouteByID("service")}
                onClick={this.navigationClickHandler}
              >
                {getRouteTextByID("service")}
              </NavLink>
              <Arrow direction={this.state.serviceArrowDirection} />
              <ServiceDropdown onNavigationClick={this.navigationClickHandler} />
            </li>
            <li
              onMouseOver={hoverOverPricesDropdown}
              onMouseOut={mouseOutPricesDropDown}
              id="prices-link-li"
              className={`text-li`}
            >
              <NavLink
                id="prices-link"
                className={`text-li`}
                to={getRouteByID("prices")}
                onClick={this.navigationClickHandler}
              >
                {getRouteTextByID("prices")}
              </NavLink>
              <Arrow direction={this.state.pricesArrowDirection} />
              <PricesDropdown onNavigationClick={this.navigationClickHandler} />
            </li>
            <li className="border-hover-effect text-li">
              <NavLink
                onClick={this.navigationClickHandler}
                to={getRouteByID("contactPage")}
              >
                {getRouteTextByID("contactPage")}
              </NavLink>
            </li>
            <li className="border-hover-effect text-li language-switch-li">
              <div className="language-switch-wrapper">
                <select
                  className="language-switch"
                  onChange={(event) => {
                    let currentLanguage = event.target.value;
                    const language = localizationUtil.getLanguageFromLocale(currentLanguage);
                    localStorage.setItem("language", currentLanguage);
                    this.setState({ language: currentLanguage });
                    window.location.href = "/" + language.path;
                  }}
                  value={
                    localStorage.getItem("language")
                      ? localStorage.getItem("language")
                      : "sv-se"
                  }

                >
                  {localizationUtil.getSupportedLanguages()
                    .filter(lang => lang.enabledForFrontend === true)
                    .map(lang => {
                      return (
                        <option key={lang.displayName} value={lang.locale}>{lang.displayName}</option>
                      )
                    })}
                </select>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Navbar);
